<template>
  <div>
    <div v-if="errorGeo">
      <v-sheet height="100vh">
        <div class="flex">
          <div class="flex flex-column">
            <div class="flex justify-center">
              <v-img
                max-width="600"
                :src="require('@/assets/img/prepare.svg')"
              ></v-img>
            </div>
            <span class="text-center text-h5 green--text mt-3">
              Address not found! Please check your default address
            </span>
            <div class="flex justify-center my-3">
              <v-progress-circular
                indeterminate
                color="green"
              ></v-progress-circular>
            </div>
          </div>
        </div>
      </v-sheet>
    </div>
    <div v-if="isLoading">
      <v-sheet height="100vh">
        <div class="flex">
          <div class="flex flex-column">
            <div class="flex justify-center">
              <v-img
                max-width="600"
                :src="require('@/assets/img/prepare.svg')"
              ></v-img>
            </div>
            <span class="text-center text-h5 green--text mt-3">
              Wrapping up your order
            </span>
            <div class="flex justify-center my-3">
              <v-progress-circular
                indeterminate
                color="green"
              ></v-progress-circular>
            </div>
          </div>
        </div>
      </v-sheet>
    </div>
    <div v-else>
      <v-container grid-list-xs class="px-0 pb-0 mt-0">
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-card
              color="white"
              class="rounded-t-xl pa-3 pt-0"
              elevation="0"
              min-height="90vh"
            >
              <div v-if="alertTop.length !== 0">
                <v-alert
                  type="error"
                  :value="true"
                  color="red"
                  v-for="(value, i) in alertTop"
                  :key="i"
                >
                  {{ value }}
                </v-alert>
              </div>
              <div>
                <v-card
                  color="white"
                  class="rounded-lg pa-3"
                  flat
                  @click="toUsers"
                >
                  <v-row>
                    <v-col cols="10" lg="11">
                      <div
                        class="flex flex-column black--text source ml-3"
                        style="line-height: 1"
                      >
                        <h4>{{ selectedUser.name || "" }}</h4>
                        <h4>{{ selectedUser.address || "" }}</h4>
                        <h4>{{ selectedUser.address2 || "" }}</h4>
                        <h4>{{ selectedUser.postcode || "" }}</h4>
                        <h4>{{ selectedUser.phone || "" }}</h4>
                        <h4>{{ selectedUser.email || "" }}</h4>
                      </div>
                    </v-col>
                    <v-col cols="2" lg="1">
                      <v-icon class="my-5" color="blue-grey"
                        >mdi-chevron-right</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-card>
                <v-divider class="my-4"></v-divider>
                <v-row v-for="(v, i) in cart" :key="i">
                  <v-col cols="12" class="py-0">
                    <v-card color="white" class="rounded-lg mt-3 pa-3">
                      <v-row>
                        <v-col
                          cols="4"
                          lg="2"
                          class="my-auto"
                          @click="selectProduct(v, i)"
                        >
                          <v-img
                            v-if="v.media_urls.length > 0"
                            class="rounded-l-lg"
                            max-height="120"
                            max-width="140"
                            :src="image(v.media_urls[0])"
                          ></v-img>
                          <v-img
                            v-else
                            class="rounded-l-lg"
                            max-height="120"
                            max-width="140"
                            :src="emptyImage()"
                          ></v-img>
                          <!-- <v-sheet v-else>
                            <span class="text-center grey lighten-3 rounded-lg"
                              ><h3 class="py-2 blue-grey--text text-lighten-3">
                                placeholder
                              </h3></span
                            >
                          </v-sheet> -->
                        </v-col>
                        <v-col cols="6" lg="9" @click="selectProduct(v, i)">
                          <div class="flex flex-column justify-space-between h">
                            <div
                              class="flex flex-column source grey--text"
                              style="line-height: 1.2"
                            >
                              <span class="flex flex-column black--text">
                                <h5>{{ v.tags[0].name }}</h5>
                                <h3>{{ v.name }}</h3>
                                <div v-if="v.modifiers.length > 0" class="">
                                  <span
                                    class="flex"
                                    v-for="(val, ind) in v.modifiers"
                                    :key="ind"
                                  >
                                    <h5>{{ showMod(val) }}</h5>
                                  </span>
                                </div>
                                <!-- {{v.variant}} -->
                                <div
                                  v-if="v.variant[0] && v.price_type == 0"
                                  class=""
                                >
                                  <span
                                    class="flex"
                                    v-for="(values, indexs) in v.variant"
                                    :key="indexs"
                                  />
                                </div>
                                <div
                                  v-if="v.variant[0] && v.price_type == 0"
                                  class=""
                                >
                                  <span
                                    class="flex"
                                    v-for="(values, indexs) in v.variant"
                                    :key="indexs"
                                  >
                                    <h5>{{ showAmt(values) }}</h5>
                                  </span>
                                </div>
                                <h5 class="font-weight-bold" v-if="v.remarks">
                                  Remarks:
                                </h5>
                                <h5
                                  class="font-weight-bold blue-grey--text"
                                  v-if="v.remarks"
                                >
                                  {{ v.remarks }}
                                </h5>
                              </span>
                            </div>
                            <div
                              class="flex black--text flex-column justify-end"
                            >
                              <h3 class="mt-1">
                                RM
                                {{
                                  v.price_type != 0
                                    ? currency(v.unit_price)
                                    : currency(v.variant[0].price)
                                }}
                              </h3>
                            </div>
                          </div>
                        </v-col>
                        <v-col
                          cols="2"
                          lg="1"
                          class="flex flex-column justify-space-between"
                        >
                          <v-btn
                            color="grey lighten-2"
                            x-small
                            fab
                            z-index="20"
                            elevation="0"
                            @click="remove(i)"
                            ><v-icon small>mdi-close</v-icon></v-btn
                          >

                          <v-btn color="gold" x-small fab elevation="0"
                            ><h3 class="font-weight-bold text-lowercase">
                              x {{ v.quantity }}
                            </h3></v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
                <!-- voucher -->
                <v-row class="mb-0" v-if="franchise.showVoucher || franchise.showVoucher == undefined">
                  <v-col class="10">
                    <v-expansion-panels class="lime lighten-4 rounded-lg">
                      <v-expansion-panel class="lime lighten-4">
                        <v-expansion-panel-header
                          color="lime--text text--darken-3"
                        >
                          Apply vouchers
                          <template v-slot:actions>
                            <v-icon color="black"> mdi-plus </v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content color="">
                          <h4 class="lime--text text--darken-3">
                            Enter a voucher code to apply
                          </h4>
                          <v-text-field
                            name="name"
                            color="gold"
                            id="id"
                            clearable
                            v-model="voucherCode"
                          ></v-text-field>
                          <div v-if="!loadVoucher">
                            <p
                              v-if="isAlert == true"
                              :class="`font-weight-bold nunito ${
                                alertStatus ? 'teal--text' : 'red--text'
                              }`"
                            >
                              {{ alertMessage }}
                            </p>
                          </div>
                          <div v-else>
                            <v-progress-circular
                              indeterminate
                              color="purple"
                            ></v-progress-circular>
                          </div>
                          <v-card-actions class="justify-end">
                            <v-btn
                              color="lime darken-3"
                              right
                              text
                              @click="checkVoucher"
                              >Apply</v-btn
                            >
                          </v-card-actions>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
                <!-- shipping -->
                <v-row no-gutters v-if="franchise.order_methods.length != 0" :class="[franchise.showVoucher || franchise.showVoucher == undefined ? '' : 'mt-5']">
                  <v-col cols="12" class="py-0">
                    <v-card class="rounded-lg">
                      <v-expansion-panels class="green lighten-4 rounded-lg">
                        <v-expansion-panel class="green lighten-4">
                          <v-expansion-panel-header
                            color="green--text text--darken-3"
                          >
                            {{
                              delyvaLoading == null
                                ? "Delivery Options"
                                : delyvaLoading == 1
                                ? "Waiting for Delivery..."
                                : "Oww... Seems like there is no delivery service near you."
                            }}
                            <span v-if="delyvaLoading == 1" class="ml-2">
                              <v-progress-circular
                                indeterminate
                                size="20"
                                color="grey"
                              ></v-progress-circular>
                            </span>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content color="">
                            <div
                              :class="$vuetify.breakpoint.mdAndUp ? 'flex' : ''"
                            >
                              <v-row
                                v-if="this.delyvaLoading == null"
                                no-gutters
                              >
                                <v-card
                                  class="mr-2 ma-1"
                                  v-for="(n, i) in ship"
                                  :color="
                                    selectedDelivery.name == n.name
                                      ? 'leaf white--text'
                                      : 'white'
                                  "
                                  :key="i"
                                  @click="shipping(n)"
                                >
                                  <div
                                    class="flex flex-column ma-2"
                                    v-if="n.name != 'unavailable'"
                                  >
                                    <span class="text-body-2">{{
                                      n.name
                                    }}</span>
                                    <span v-if="n.data"
                                      >+ RM{{
                                        currency(n.data.delivery_fee)
                                      }}</span
                                    >
                                  </div>
                                </v-card>
                              </v-row>
                            </div>
                            <!-- </div> -->
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-card>
                  </v-col>
                </v-row>
                <!-- summary -->
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-card class="rounded-lg" elevation="0">
                      <div class="pa-3 source black--text">
                        <h3>Summary</h3>
                        <v-divider class="my-2" v-if="total"></v-divider>
                        <div v-if="total">
                          <div class="flex justify-space-between px-3">
                            <h4 v-if="inclusive.status">
                              Subtotal (Include SST tax)
                            </h4>
                            <h4 v-else>Subtotal</h4>
                            <h4 class="lato">
                              {{ currency(total) }}
                            </h4>
                          </div>
                        </div>
                        <div
                          class="flex justify-space-between px-3"
                          v-if="voucher"
                        >
                          <h4>
                            Voucher
                            {{ voucher.delivery ? "(Delivery)" : "(Item)" }}
                          </h4>
                          <h4 class="lato" v-if="voucher.deduct">
                            - {{ currency(voucher.deduct) }}
                          </h4>
                          <h4 class="lato" v-if="voucher.discount">
                            - {{ voucher.discount }}%
                          </h4>
                          <h4
                            class="lato"
                            v-if="selectedDelivery != null && voucher.delivery"
                          >
                            -
                            {{
                              currency(
                                selectedDelivery.data.delivery_fee -
                                  voucher.delivery
                              ) > 0
                                ? currency(voucher.delivery)
                                : currency(selectedDelivery.data.delivery_fee)
                            }}
                          </h4>
                        </div>
                        <div v-if="inclusive.status == false">
                          <div
                            class="flex justify-space-between px-3"
                            v-if="currency(taxed).value !== 0"
                          >
                            <h4>SST Tax ({{ parseInt(taxed) }}%)</h4>
                            <h4 class="lato">
                              +
                              {{ currency(total).multiply(taxed).divide(100) }}
                            </h4>
                          </div>
                        </div>
                        <div class="flex px-3" v-if="selectedDelivery != null">
                          <h4>Delivery :</h4>
                          <div
                            class="flex justify-space-between"
                            v-if="
                              selectedDelivery.provider &&
                              selectedDelivery.provider !== ''
                            "
                          >
                            <h4
                              class="lato text-capitalize ml-2"
                              v-if="selectedDelivery"
                            >
                              {{ selectedDelivery.name }}
                            </h4>
                            <h4 class="lato">
                              +
                              {{ currency(selectedDelivery.data.delivery_fee) }}
                            </h4>
                          </div>
                          <div v-else>
                            <h4
                              class="lato text-capitalize ml-2"
                              v-if="selectedDelivery"
                            >
                              {{ selectedDelivery.name }}
                            </h4>
                          </div>
                        </div>
                        <div
                          v-if="franchise.packaging_fees"
                          class="flex justify-space-between px-3"
                        >
                          <h4>Packaging Fees</h4>
                          <h4 class="lato">
                            +
                            {{ currency(franchise.packaging_fees) }}
                          </h4>
                        </div>
                        <div class="flex justify-space-between">
                          <h3>Total</h3>
                          <h3 class="lato">
                            RM
                            {{ totaled() }}
                          </h3>
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <div>
                      <v-btn
                        block
                        color="leaf"
                        dark
                        large
                        elevation="5"
                        rounded
                        :disabled="alertTop.length > 0 ? true : false"
                        @click="checkout"
                        ><span v-if="isCheckout">
                          <v-progress-circular
                            indeterminate
                            color="white"
                          ></v-progress-circular>
                        </span>
                        <span v-else>Checkout</span></v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import currency from "currency.js";

export default {
  name: "cart",

  data() {
    return {
      alertTop: [],
      isLoading: true,
      isCheckout: false,
      alertMessage: "",
      alertStatus: false,
      errorGeo: false,
      isAlert: true,
      loadVoucher: false,
      total: null,
      selected: null,
      voucherCode: "",
      selectedDelivery: null,
      userLog: null,
      merchantLog: null,
      delyvaLoading: null,
    };
  },

  computed: {
    ...mapGetters({
      cart: "getCart",
      user: "getUsers",
      voucher: "getVoucher",
      ship: "getDelivery",
      franchise: "getFranchise",
      inclusive: "getInclusive",
      selectedOutlet: "getSelectedOutlet",
    }),

    currency() {
      return require("currency.js");
    },

    taxed() {
      var tax = "";
      if (this.franchise.outlets.length > 0) {
        tax =
          this.inclusive.taxes.length > 0
            ? this.inclusive.taxes[0].amount
            : "0";
      } else if (this.franchise.tax && this.franchise.tax !== "0") {
        tax = this.franchise.tax;
      }
      return tax;
    },
    selectedUser() {
      return this.user.find((d) => d.selected == true);
    },
  },

  watch: {
    ship() {
      if (
        this.franchise.order_methods.includes("delivery") &&
        this.franchise.delivery_method.includes("delyvax")
      ) {
        if (this.ship.some((p) => p.provider == "delyvax") == false) {
          this.delyvaLoading = 1; //status loading
        } else {
          if (
            this.ship.some((p) => p.name == "unavailable") &&
            this.ship.length == 1
          ) {
            this.delyvaLoading = 2; //status delyva services unavailable
          } else {
            this.shipping(this.ship[0]);
            this.delyvaLoading = null;
          }
        }
      } else {
        if (
          this.selectedDelivery == null &&
          this.franchise.order_methods.length != 0 &&
          this.franchise.delivery_method.length != 0
        ) {
          this.shipping(this.ship[0]);
        }
      }
    },
  },

  methods: {
    getOut(v) {
      console.log(v);
    },

    image(i) {
      if (i.s512) {
        return i.s512;
      } else
        return (
          "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
          i.original
        );
    },
    emptyImage() {
      return (
        "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
        this.franchise.logo
      );
    },

    async shipping(i) {
      if (i.name != "unavailable") {
        //check whether the first array unavailable service or not
        if (
          this.franchise.min_order &&
          Object.keys(this.franchise.min_order).length > 0
        ) {
          var min =
            this.franchise.min_order[i.provider ? "delivery" : "pickup"];
          if (min) {
            if (min.app_to == "quantity") {
              let sum = this.cart
                .map((d) => d.quantity)
                .reduce((a, b) => a + b);
              if (min.amount !== "" && sum < min.amount) {
                let v = `Your cart does not meet minimum order of ${min.amount} items`;
                let k = this.alertTop.findIndex((d) =>
                  d.includes("minimum order")
                );
                k == -1 ? this.alertTop.push(v) : (this.alertTop[k] = v);
              } else {
                let k = this.alertTop.findIndex((d) =>
                  d.includes("minimum order")
                );
                k !== -1 ? this.alertTop.splice(k) : null;
              }
            } else {
              if (min.amount !== "" && this.total < min.amount) {
                let v = `Your cart does not meet minimum order of RM${currency(
                  min.amount
                )} net spend`;
                let k = this.alertTop.findIndex((d) =>
                  d.includes("minimum order")
                );
                k == -1 ? this.alertTop.push(v) : (this.alertTop[k] = v);
              } else {
                let k = this.alertTop.findIndex((d) =>
                  d.includes("minimum order")
                );
                k !== -1 ? this.alertTop.splice(k) : null;
              }
            }
          }
        }
        this.selectedDelivery = i;
      } else {
        this.delyvaLoading = 2;
      }
    },

    showMod(mod) {
      var prefix = mod.mode == 1 ? "+ " : mod.mode == 2 ? "- " : "",
        suffix = mod.mode == 3 ? "% " : "";
      var amt =
        mod.amount > 0 ? ` ${prefix} RM${mod.amount.toFixed(2)}${suffix}` : "";
      return `${mod.name}${amt}`;
    },

    showAmt(vars) {
      if (typeof vars.price == "string") {
        vars.price = parseFloat(vars.price);
      }

      return `${vars.name}`;
    },

    selectProduct(v, i) {
      console.log(v, i, "v dan i");
      let d = {
        value: v,
        index: i,
      };
      this.$store.dispatch("editSP", d);
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "product" },
      });
    },

    add(i) {
      this.cart[i].quantity++;
      this.$store.dispatch("updateCart", this.cart);
    },

    async remove(i) {
      this.$store.dispatch("removeCart", i);
      this.total = await this.$store.dispatch("cartTotal");
      this.checkVoucher();
    },

    toProducts() {
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "products" },
      });
    },

    toUsers() {
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "users" },
      });
    },

    totaled() {
      var sum = this.total;
      let discDelivery = 0.0;
      let calcDelivery;

      sum = currency(sum);
      if (this.franchise.outlets.length > 0 && this.inclusive.status == false) {
        var inc = sum.multiply(
          (this.inclusive.taxes.length > 0
            ? this.inclusive.taxes[0].amount
            : 0) / 100
        );
        sum = sum.add(inc.value);
      } else if (this.franchise.tax && this.franchise.tax !== "0") {
        var a = sum.multiply(this.franchise.tax / 100);
        sum = sum.add(a.value);
      }

      if (this.voucher) {
        if (this.voucher.discount) {
          sum = sum.multiply((100 - this.voucher.discount) / 100);
        }
        if (this.voucher.deduct) {
          sum = sum.subtract(this.voucher.deduct);
        }
        if (this.voucher.delivery) {
          discDelivery = this.voucher.delivery;
        }
      }

      // if (this.selectedDelivery) {
      //   // console.log("selected", this.selectedDelivery);
      //   if (
      //     this.selectedDelivery.provider == "speedy" ||
      //     this.selectedDelivery.provider == "lalamove" ||
      //     this.selectedDelivery.provider == "bungkusit"
      //   ) {
      //     // sum += this.selectedDelivery.data.delivery_fee;
      //     sum = sum.add(this.selectedDelivery.data.delivery_fee);
      //   } else if (this.selectedDelivery.provider == "own_delivery") {
      //     // sum += parseInt(this.selectedDelivery.data.delivery_fee);
      //     sum = sum.add(this.selectedDelivery.data.delivery_fee);
      //   }
      // }

      if (this.selectedDelivery) {
        if (this.selectedDelivery.provider == "delyvax") {
          calcDelivery = this.selectedDelivery.data.delivery_fee;
          if (discDelivery) {
            calcDelivery =
              this.selectedDelivery.data.delivery_fee - this.voucher.delivery;
            calcDelivery = calcDelivery < 0 ? 0.0 : calcDelivery;
          }
          sum = sum.add(calcDelivery);
        } else if (this.selectedDelivery.provider == "own_delivery") {
          calcDelivery = this.selectedDelivery.data.delivery_fee;
          if (discDelivery) {
            calcDelivery =
              this.selectedDelivery.data.delivery_fee - this.voucher.delivery;
            calcDelivery = calcDelivery < 0 ? 0.0 : calcDelivery;
          }
          sum = sum.add(this.selectedDelivery.data.delivery_fee);
        }
      }

      if (this.franchise.packaging_fees) {
        sum = sum.add(this.franchise.packaging_fees);
      }

      return sum;
    },

    async checkout() {
      window.fbq("track", "Purchase");
      if (this.cart.length == 0) {
        alert("Please add an item to the cart");
      } else {
        this.isCheckout = true;
        let uuid = await this.$store.dispatch("uuid");
        this.selectedUser.uuid = uuid;
        delete this.selectedUser.selected;

        let oGeo = this.franchise.outlets.find(
          (d) => d.name == this.selectedOutlet
        );

        let mercGeo = {
          address: oGeo ? oGeo.address : this.franchise.address,
          address2: oGeo ? oGeo.address2 : this.franchise.address2,
        };

        let fGeo = await this.$store.dispatch("fetchGeocode", mercGeo);
        var weight = this.cart.reduce(function (sum, item) {
          if (item.weight) {
            return (sum = sum + item.weight.converted * item.quantity);
          } else {
            return (sum = sum + 0.5 * item.quantity);
          }
        }, 0);

        let data = {
          customer: this.selectedUser,
          cart: this.cart,
          delivery: false,
          referral: "",
          notes: "",
        };
        if (this.selectedDelivery.provider) {
          data.delivery = true;
          data.delivery_detail = {
            provider: this.selectedDelivery.provider,
            pickup_address1: `${fGeo.address}`,
            pickup_address2: `${fGeo.address2}`,
            dropoff_address1: `${this.selectedUser.address}`,
            dropoff_address2: `${this.selectedUser.address2}`,
            pickup_city: `${fGeo.city}`,
            pickup_state: `${fGeo.state}`,
            pickup_pc: `${oGeo ? oGeo.postcode : this.franchise.postcode}`,
            dropoff_city: `${this.userLog.city}`,
            dropoff_state: `${this.userLog.state}`,
            dropoff_pc: `${this.selectedUser.postcode}`,
            pickup_long: fGeo.longitude,
            pickup_lat: fGeo.latitude,
            dropoff_long: this.userLog.longitude || "",
            dropoff_lat: this.userLog.latitude || "",
            fire_id: this.franchise.fire_id,
            item_weight: weight,
            service: this.selectedDelivery.name,
            service_code: this.selectedDelivery.code,
            order_receipt_items: this.cart.map((d) => ({
              name: d.name,
              quantity: d.quantity,
            })),
          };
        }
        // console.log(data.delivery_detail, 'delivesry details')
        if (this.franchise.payments && this.franchise.payments.length == 1) {
          data.paydirect = this.franchise.payments[0];
        }

        var today = new Date();
       
        var ls = data;
        ls.datetime = today.toLocaleString();
        ls.fire_id = this.franchise.fire_id;
        ls.totalOrder = this.totaled().value;
        this.$store.dispatch("saveOrder", ls);
        this.$store.dispatch("checkout", data);
      }
    },

    checkVoucher() {
      this.loadVoucher = true;
      this.$stores
        .dispatch("findVoucher", {
          voucher: this.voucherCode,
          total: this.total,
        })
        .then((r) => {
          if (!r.error) {
            //check minimum spending
            if (r.min && this.total >= r.min) {
              this.loadVoucher = false;
              this.alertStatus = true;
              this.alertMessage = "Your voucher has been applied";
              this.isAlert = true;
            } else {
              this.loadVoucher = false;
              this.alertStatus = false;
              this.alertMessage =
                "Your subtotal does not meet minimum amount of RM" +
                currency(r.min).value;
              this.isAlert = true;
            }
          } else {
            this.loadVoucher = false;
            this.alertStatus = false;
            this.alertMessage = r.error;
            this.isAlert = true;
          }
        });
    },
  },

  async beforeMount() {
    this.isLoading = true;
    if (this.user.length < 1) {
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "users" },
      });
    }

    this.total = await this.$store.dispatch("cartTotal");
    let c = await this.$store.dispatch("fetchGeocode", this.selectedUser);
    if (c == "ZERO_RESULTS") {
      let v = "Address not found. Please reconfirm your address";
      this.alertTop.push(v);
    }
    this.userLog = c;
    var deliver = [];

    if (this.franchise.order_methods.includes("pickup") == true) {
      this.$store.dispatch("fetchPickup");
    }

    if (this.franchise.delivery_method.includes("[")) {
      deliver = JSON.parse(this.franchise.delivery_method);
    } else deliver = this.franchise.delivery_method;

    if (this.franchise.order_methods.includes("delivery")) {
      if (
        deliver.includes("own_delivery") ||
        this.franchise.delivery_method == "own_delivery"
      )
        this.$store.dispatch("fetchOwnDelivery");
      if (
        deliver.includes("delyvax") ||
        this.franchise.delivery_method == "delyvax"
      ) {
        this.$store.dispatch("fetchDelyvaFees", this.selectedUser);
      }
    }

    if (this.selectedDelivery == null) {
      this.shipping(this.ship[0]);
    }

    this.isLoading = false;
  },
};
</script>
<style>
.h {
  height: 100%;
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #279474 !important;
  opacity: 0.8 !important;
}
</style>
